import React from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import popUp from "../../../assets/jackImages/pop-up.png";
import playstore from "../../../assets/jackImages/google-play.png";
import appstore from "../../../assets/jackImages/appstore.png";

import { GothamMedium, GothamRegular } from "../../../components/Text";

import { PopupModal } from "./modal";
import { useModalHook } from "../../../components/Modals";
import { PopupComponent } from "./component";

export const PopUp = ({
  isOpen: isOpenProps,
  toggle: toggleProps,
  isLogin,
}) => {
  const { isOpen, toggle } = useModalHook();

  const imgWidth = 184 / 2 - 4;

  return (
    <>
      <PopupComponent
        isOpen={isOpenProps}
        toggle={toggleProps}
        toggleModal={toggle}
        type={isLogin ? "white" : "black"}
      >
        {isLogin ? (
          <>
            <GothamRegular className="font12" style={{ textAlign: "center" }}>
              Take control on the go,
            </GothamRegular>
            <GothamMedium className="font12" style={{ textAlign: "center" }}>
              download our mobile app
            </GothamMedium>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: 12 }}
            >
              <img src={playstore} style={{ width: imgWidth }} />
              <img src={appstore} style={{ width: imgWidth }} />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                position: "absolute",
                right: 0,
                zIndex: -1,
                bottom: 0,
                borderRadius: 8,
              }}
            >
              <img src={popUp} style={{ width: 158 / 2 }} />
            </div>

            <GothamRegular
              style={{ color: "white", marginBottom: 2 }}
              className="font12"
            >
              Take control on the go,
            </GothamRegular>
            <GothamMedium
              style={{ color: "white", marginBottom: 8 }}
              className="font12"
            >
              download our mobile app
            </GothamMedium>

            <div className="d-flex align-items-center" style={{ gap: 4 }}>
              <GothamMedium
                style={{ color: jackColors.greenB9 }}
                className="font12"
              >
                Download App
              </GothamMedium>
              <JackIcons
                name="arrow-forward-outline"
                fill={jackColors.greenB9}
                style={{ width: 20 }}
              />
            </div>
          </>
        )}
      </PopupComponent>

      <PopupModal isOpen={isOpen} toggle={toggle} />
    </>
  );
};
