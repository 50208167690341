import React, { useEffect, useRef, useState } from "react";
import content1 from "../../../assets/jackImages/popup-content-1.png";
import qr from "../../../assets/jackImages/qr-app.png";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { Modal } from "reactstrap";
import { jackColors } from "../../../assets/colors";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { windowDimension } from "../../../components/tools";

export const PopupModal = ({ isOpen, toggle }) => {
  if (!isOpen) return null;

  const imgs = [content1];
  const ref = useRef();

  const [index, setIndex] = useState(0);

  const imageRatio = 156 / 438;
  const { width } = windowDimension();

  const isSmall = width < 438;

  const defaultWidth = isSmall ? width * 0.9 : 438;

  useEffect(() => {
    let interval = setInterval(() => {
      setIndex((p) => {
        const isLast = p >= imgs.length - 1;
        if (isLast) return 0;
        return p + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTo({ left: index * defaultWidth });
  }, [index]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      contentClassName="modal-popup"
      modalClassName="centered-popup"
    >
      <div
        style={{
          overflowX: "hidden",
          width: defaultWidth,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
        className="d-flex"
        ref={ref}
      >
        {imgs.map((src) => (
          <img src={src} style={{ width: defaultWidth }} />
        ))}
        {imgs.length > 1 && (
          <div
            style={{
              position: "fixed",
              marginTop: imageRatio * defaultWidth,
              marginLeft: 20,
              gap: 4,
            }}
            className="d-flex"
          >
            {imgs.map((_, idx) => {
              const isActive = idx == index;
              return (
                <div
                  style={{
                    height: 1,
                    width: 12,
                    backgroundColor: jackColors.greyBB,
                  }}
                  key={idx}
                >
                  {isActive && (
                    <div
                      className="linear-width"
                      style={{
                        backgroundColor: "white",
                        height: 1,
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className="d-flex align-items-center"
        style={{
          gap: 32,
          flexDirection: "column",
          padding: 16,
          paddingTop: 32,
        }}
      >
        <img src={qr} style={{ width: 160 }} />
        <div>
          <GothamMedium
            style={{ textAlign: "center", marginBottom: 12 }}
            className="font20"
          >
            Get the Jack Finance App
          </GothamMedium>
          <GothamRegular style={{ textAlign: "center" }}>
            Scan the QR code to download app
          </GothamRegular>
        </div>
        <ButtonJack type="outline" style={{ width: "100%" }} onClick={toggle}>
          Close
        </ButtonJack>
      </div>
    </Modal>
  );
};
